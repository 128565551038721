import { defineStore } from 'pinia'

export const usePopupStore = defineStore('popup', {
  state: () => ({
    showPopup: false,
    type: '',
  }),
  actions: {
    popup(value: boolean, type: string) {
      this.showPopup = value
      this.type = type
    }
  }
})